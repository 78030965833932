import React, { useContext } from 'react'
import { Link } from 'gatsby'
import StoreContext from '../context/StoreContext'

import styles from '../css/line-item.module.css'

const LineItem = ({ item }) => {
    const { removeLineItem, store: { client, checkout } } = useContext(StoreContext)

    const ThumbNail = item.variant.image ? (
        <img
            className="my-2 w-32 tablet:my-0"
            src={item.variant.image.src}
            alt={`${item.title} product thumbnail`}
        />
    ) : null

    const selectedOptions = item.variant.selectedOptions.map( option => 
        option.value === 'Default Title' ? null :
            `${option.value.toUpperCase()}`
        )

    const handleRemove = () => {
        removeLineItem(client, checkout.id, item.id)
    }

    return(
        <>
            <div className="bg-dkblue flex" style={{ marginBottom: '2px' }}>
                
                <div className={`${styles.col1} flex items-center justify-around`}>
                    <span>{item.quantity}</span>
                </div>

                <Link className={`${styles.col2} flex items-center justify-around`} to={`/product/${item.variant.product.handle}`}>
                    {ThumbNail}
                </Link>

                <Link className={`${styles.col3} flex items-center`} to={`/product/${item.variant.product.handle}`}>
                    <span className="text-xs tablet:text-sm px-2">
                        {item.title}
                        {selectedOptions[0] !== null && ' - '}
                        {selectedOptions.map( option => option !== null && `${option} ` )}
                    </span>
                </Link>

                <div className={`${styles.col4} flex items-center justify-around`}>
                    <span className="text-xs tablet:text-sm">${item.variant.price}</span>
                </div>

                <div className={`${styles.col5} flex items-center justify-around`}>
                    <button className="w-6" onClick={handleRemove}>
                        X
                    </button>
                </div>
            </div>
        </>
    )
}

export default LineItem;