import React, { useState } from "react"

import ContextProvider from '../context/ContextProvider'

import Header from "./header"
import MobileMenu from './mobile-menu'
import ShoppingCart from '../components/shopping-cart'
import SubscribeWidget from '../components/subscribe-widget'
import Footer from './footer'

const Layout = ({ children }) => {

    const [mobileMenu, setMobileMenu] = useState(false);
    const [shoppingCart, setShoppingCart] = useState(false);

  return (
      <ContextProvider>
        <Header
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            shoppingCart={shoppingCart}
            setShoppingCart={setShoppingCart}
        />

        { mobileMenu === true && <div className="mobile-shade" /> }
        <div className={`desktop-shade ${ shoppingCart === true ? `desktop-shade-active` : `` }`} />

        <MobileMenu
            mobileMenu={mobileMenu}
        />

        <ShoppingCart
          shoppingCart={shoppingCart}
          setShoppingCart={setShoppingCart}
        />
            
            <main>{children}</main>

        <SubscribeWidget />

        <Footer />
        
      </ContextProvider>
  )
}

export default Layout
