import React, { useState, useContext, useEffect } from 'react'
import StoreContext from '../context/StoreContext'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from '../css/subscribe-widget.module.css'

import Rocket from '../icons/rocket.svg'
import Close from '../icons/close.svg'
import Philoso from '../icons/philoso.svg'
import Aymi from '../icons/aymi.svg'
import Smurgle from '../icons/smurgle.svg'
import SadPhiloso from '../icons/sad-philoso.svg'
import SadAymi from '../icons/sad-aymi.svg'
import SadSmurgle from '../icons/sad-smurgle.svg'

const SubscribeWidget = () => {

    const { currentPage, notifyCodeCopied } = useContext(StoreContext);

    const [reveal, setReveal] = useState(false);
    const [popUp, setPopUP] = useState(false);
    const [email, setEmail] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [showCode, setShowCode] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setReveal(true);
        }, 3000)
        if (currentPage === 'Home') {
            clearTimeout(timer);
            setReveal(false);
        }
        return () => {
            clearTimeout(timer);
            setReveal(false);
        }
    }, [currentPage, setReveal])

    const handleClose = () => {
        setPopUP(false);
        setEmail('');
        setInvalidEmail(false);
    }

    const handleSubscribe = async (e) => {
        e.preventDefault();
        const res = await addToMailchimp(email);
        if (res.msg === "The email you entered is not valid.") {
            setInvalidEmail(true);
        } else {
            setShowCode(true);
            setEmail('');
            setInvalidEmail(false);
        }
    }

    return(
        <>
            <button
                className={`${styles.floater} ${reveal === true && styles.floaterActive}`}
                onClick={() => setPopUP(true)}
            >
                <img
                    alt="Rare rocket"
                    src={Rocket}
                    className={styles.rocket}
                />
            </button>
            <div className={`${styles.popUp} ${popUp === true && styles.popUpActive}`}>
                <div className="bg-dkpink w-full rounded-xl relative" style={{ maxWidth: '400px' }}>
                    { invalidEmail === true ?
                        <img
                            alt="Philoso"
                            className={styles.philoso}
                            src={SadPhiloso}
                        /> :
                        <img
                            alt="Philoso"
                            className={styles.philoso}
                            src={Philoso}
                        />
                    }
                    { invalidEmail === true ?
                        <img
                            alt="Aymi"
                            className={styles.aymi}
                            src={SadAymi}
                        /> :
                        <img
                            alt="Aymi"
                            className={styles.aymi}
                            src={Aymi}
                        />
                    }
                    { invalidEmail === true ?
                        <img
                            alt="Smurgle"
                            className={styles.smurgle}
                            src={SadSmurgle}
                        /> :
                        <img
                            alt="Smurgle"
                            className={styles.smurgle}
                            src={Smurgle}
                        />
                    }
                    <div className="w-full flex justify-end mb-2">
                        <button
                            className="mr-2 mt-2"
                            onClick={handleClose}
                        >
                            <img
                                alt="close button"
                                src={Close}
                                className="opacity-50"
                            />
                        </button>
                    </div>
                    <p className="text-dkblue text-center font-bold text-lg mb-2">
                        { showCode === true ? 
                            `Thanks for subscribing!` :
                            `Can we be friends?`
                        }
                    </p>
                    <hr className="leading-none border-dkblue w-48 mx-auto border-opacity-20 mb-2"/>
                    { showCode === true ?
                        <p className="text-center mb-12 text-dkblue font-bold text-sm opacity-70">
                            Please enjoy this 10% off code
                        </p> :
                        <p className="text-center text-dkblue font-bold text-sm opacity-70">
                            Join us and recieve a 10% discount<br/>code for your entire purchase.
                    </p> }
                    <CopyToClipboard
                        text={`XZHYKK77RZV3`}
                        onCopy={() => notifyCodeCopied()}
                    >
                        <span className={`${styles.discountCode} ${showCode === true && styles.displayCode}`}>
                            XZHYKK77RZV3
                        </span>
                    </CopyToClipboard>
                    { showCode === true ?
                        <div style={{ paddingTop: '45px' }}>
                            <button
                                className={styles.submit}
                                onClick={handleClose}
                            >
                                Got it!
                            </button>
                        </div> :
                        <form
                            id="subscribe-form"
                            className="relative"
                            onSubmit={e => handleSubscribe(e)}
                        >
                            <span
                                className={`${styles.invalidMessage} ${invalidEmail === true && styles.displayInvalid}`}
                            >
                                Email Invalid. Try again.
                            </span>
                            <input
                                id="email"
                                className={styles.input}
                                aria-label="email address"
                                type="text"
                                placeholder="friend@email.com"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                            <button
                                type="submit"
                                form="subscribe-form"
                                className={styles.submit}
                            >
                                Submit
                            </button>
                    </form> }
                </div>
            </div>
        </>
    )
}

export default SubscribeWidget;