import React, { useEffect, useState } from 'react'
import fetch from 'isomorphic-fetch'
import Client from 'shopify-buy'
import Context from './StoreContext'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
toast.configure()

const notifyItemAdded = () => {
    toast.success(`Item(s) added to cart`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
        progressStyle: { backgroundColor: "#ffffff" },
        style: { backgroundColor: "rgb(37, 47, 62)", color: '#ffffff' }
    });
}

const notifyItemRemoved = () => {
    toast.success(`Item(s) removed from cart`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
        progressStyle: { backgroundColor: "#ffffff" },
        style: { backgroundColor: "rgb(37, 47, 62)", color: '#ffffff' }
    });
}

const notifySubscribed = () => {
    toast.success(`Email accepted`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
        progressStyle: { backgroundColor: "#ffffff" },
        style: { backgroundColor: "#FFB8D1", color: '#252F3E' }
    })
}


const client = Client.buildClient(
    {
      storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
      domain: `${process.env.SHOP_NAME}.myshopify.com`,
    },
    fetch
)

const ContextProvider = ({ children }) => {
    let initialStoreState = {
        client,
        adding: false,
        removing: false,
        checkout: { lineItems: [] },
        // products: [],
        // shop: {},
    }
    
    const [store, updateStore] = useState(initialStoreState)
    const [currentPage, setCurrentPage] = useState('');
    const [scrollLock, setScrollLock] = useState(false);
    const [mobileProductsMenu, setMobileProductsMenu] = useState(false);
    const [shareWidget, setShareWidget] = useState(false);
    let isRemoved = false

    const notifyTextCopied = () => {
        toast.success(`Copied to Clipboard!`, {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            progressStyle: { backgroundColor: "#ffffff" },
            style: { backgroundColor: "rgb(37, 47, 62)", color: '#ffffff' }
        });
    }

    const notifyCodeCopied = () => {
        toast.success(`Copied to Clipboard!`, {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            progressStyle: { backgroundColor: "#ffffff" },
            style: { backgroundColor: "#FFB8D1", color: '#252F3E' }
        });
    }

    useEffect(() => {
        const initializeCheckout = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== 'undefined'
        const existingCheckoutID = isBrowser ? localStorage.getItem('shopify_checkout_id') : null
    
        const setCheckoutInState = checkout => {
            if (isBrowser) {
                localStorage.setItem('shopify_checkout_id', checkout.id)
            }
    
            updateStore(prevState => {
                return { ...prevState, checkout }
            })
        }
    
        const createNewCheckout = () => store.client.checkout.create()
        const fetchCheckout = id => store.client.checkout.fetch(id)
    
        if (existingCheckoutID) {
            try {
                const checkout = await fetchCheckout(existingCheckoutID)
                // Make sure this cart hasn’t already been purchased.
                if (!isRemoved && !checkout.completedAt) {
                    setCheckoutInState(checkout)
                    return
                }
            } catch (e) {
                localStorage.setItem('shopify_checkout_id', null)
            }
        }
    
        const newCheckout = await createNewCheckout()
            if (!isRemoved) {
                setCheckoutInState(newCheckout)
            }
        }
    
        initializeCheckout()
      }, [store.client.checkout])

    useEffect( () => () => {
        isRemoved = true
    }, [])

    return(
        <Context.Provider
            value={{
                store,
                currentPage,
                setCurrentPage,
                scrollLock,
                setScrollLock,
                mobileProductsMenu,
                setMobileProductsMenu,
                shareWidget,
                setShareWidget,
                notifyTextCopied,
                notifyCodeCopied,
                notifySubscribed,
                addVariantToCart: (variantId, quantity) => {
                    if (variantId === '' || !quantity) {
                        console.error('You must select a quantity.')
                        return
                    }

                    updateStore(prevState => {
                        return { ...prevState, adding: true }
                    })

                    const { checkout, client } = store

                    const checkoutId = checkout.id

                    const lineItemsToUpdate = [
                        { variantId, quantity: parseInt(quantity, 10) },
                    ]

                    return client.checkout
                        .addLineItems(checkoutId, lineItemsToUpdate)
                        .then(checkout => {
                            updateStore(prevState => {
                                return { ...prevState, checkout, adding: false }
                            })
                            notifyItemAdded()
                            console.log(checkout)
                        })
                    },

                removeLineItem: (client, checkoutID, lineItemID) => {

                    updateStore(prevState => {
                        return { ...prevState, removing: true }
                    })

                    return client.checkout
                        .removeLineItems(checkoutID, [lineItemID])
                        .then(res => {
                            updateStore(prevState => {
                                return { ...prevState, checkout: res, removing: false }
                            })
                            notifyItemRemoved()
                        })
                },

                updateLineItem: (client, checkoutID, lineItemID, quantity) => {
                    const lineItemsToUpdate = [
                        { id: lineItemID, quantity: parseInt(quantity, 10) },
                    ]

                    return client.checkout
                        .updateLineItems(checkoutID, lineItemsToUpdate)
                        .then(res => {
                            updateStore(prevState => {
                            return { ...prevState, checkout: res }
                            })
                        })
                },
            }}
            >
                {children}
        </Context.Provider>
    )
}

export default ContextProvider;