import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import ExternalLink from '../utils/external-link'

import styles from '../css/footer.module.css'

import { FacebookSVG, InstagramSVG, TwitterSVG, LinkedinSVG, GitHubSVG, HeartSVG, WebSVG, MailSVG } from './inline-svgs'
import MobileTitle from '../images/mobile-footer-title.svg'

const Footer = () => {

    const SocialIcon = ({ SVG, LinkType, href }) => (
        <LinkType href={href} className="flex items-center">
            <SVG className="fill-current" />
        </LinkType>
    )

    return(
        <>
        <div className="flex overflow-hidden mt-12 tablet:hidden" style={{ height: '40px', padding: '0 16px' }}>
            <StaticImage
                alt="Mushrooms"
                src="../images/mobile-mushrooms.png"
                layout="fixed"
                placeholder="none"
                width={768}
                imgStyle={{
                    objectFit: 'none',
                    objectPosition: 'left bottom',
                    width: '768px',
                    height: '40px'
                }}
            />
        </div>
        <div className="hidden tablet:flex overflow-hidden max-viewport mx-auto mt-12 relative z-20 bg-dkblue" style={{ height: '60px', padding: '0 16px' }}>
            <StaticImage
                alt="Mushrooms"
                src="../images/mushrooms.png"
                layout="fixed"
                placeholder="none"
                width={1280}
                imgStyle={{
                    objectFit: 'none',
                    objectPosition: 'left bottom',
                    width: '1280px',
                    height: '60px'
                }}
            />
        </div>
        <footer className={`${styles.footer} w-full bg-dkblue absolute`}>

            <div className={`${styles.footerMain} max-viewport mx-auto flex flex-wrap`}>

                <div className="w-full laptop:w-3/4 laptop:flex items-center">

                    <img
                        src={MobileTitle}
                        className="w-11/12 mx-auto laptop:hidden"
                        alt="Rare Threads banner"
                        style={{ maxWidth: '600px' }} 
                    />

                    <h2 className="hidden laptop:block" style={{ marginLeft: '16px' }}>
                        Rare Threads
                    </h2>

                </div>

                <div className="w-full flex justify-around laptop:w-1/4 laptop:justify-end">
                    <StaticImage
                        alt="Rare Chicago blue clouds logo"
                        src="../images/rare-chicago-clouds-blue.png"
                        className={styles.logo}
                        width={420}
                        placeholder="tracedSVG"
                        style={{
                            width: '80%',
                            maxWidth: '420px'
                        }}
                    />
                </div>

                <div className="w-full laptop:hidden" style={{ marginTop: '96px' }}>
                    <div className="w-2/3 flex justify-around" style={{ maxWidth: '375px', margin: '16px auto' }}>
                        <SocialIcon
                            SVG={FacebookSVG}
                            LinkType={ExternalLink}
                            href="https://www.facebook.com/rarechicagotech"
                        />
                        <SocialIcon
                            SVG={InstagramSVG}
                            LinkType={ExternalLink}
                            href="https://www.instagram.com/rarechicagotech"
                        />
                        <SocialIcon
                            SVG={TwitterSVG}
                            LinkType={ExternalLink}
                            href="https://www.twitter.com/rarechicagotech"
                        />
                        <SocialIcon
                            SVG={LinkedinSVG}
                            LinkType={ExternalLink}
                            href="https://www.linkedin.com/company/rarechicago"
                        />
                        <SocialIcon
                            SVG={GitHubSVG}
                            LinkType={ExternalLink}
                            href="https://github.com/rarechicago"
                        />
                    </div>
                </div>

                <div className="hidden laptop:flex w-full" style={{ marginTop: '48px' }}>

                    <div className="w-3/4 text-white" style={{ paddingLeft: '16px' }}>

                        <a 
                            className="flex items-center"
                            href="https://rarechicago.com/"
                        >
                            <WebSVG className="fill-current"/>
                            <p style={{ margin: '0 0 0 8px' }}>rarechicago.com</p>
                        </a>
                        <a
                            className="flex items-center"
                            href="mailto:hello@rarechicago.com"
                            style={{ marginTop: '8px' }}
                        >
                            <MailSVG className="fill-current" />
                            <p style={{ margin: '0 0 0 8px' }}>hello@rarechicago.com</p>
                        </a>

                    </div>

                    <div className="w-1/4 flex justify-end self-end">
                        <div className="flex w-5/6 justify-around text-sm" style={{ marginRight: '16px' }}>
                            <Link
                                to="/products/"
                            >
                                Products
                            </Link>
                            <a
                                href="https://rarechicago.com/about/"
                            >
                                About Rare
                            </a>
                            <Link
                                to="/support/"
                            >
                                Support
                            </Link>
                        </div>
                    </div>

                </div>

                <div className="hidden laptop:flex w-full opacity-20" style={{ padding: '0 16px', marginTop: '48px' }}>
                    <hr className="w-full mx-auto bg-white h-px" />
                </div>

                <div className="flex w-full">

                    <div className={`${styles.footnoteContainer} w-full laptop:flex laptop:w-3/4 items-center`}>
                        <p className={`${styles.footnoteOne} text-center`}>&#169; 2021 Rare Chicago LLC all rights reserved</p>
                        <a
                            href="https://rarechicago.com/"
                            className={`${styles.footnoteTwo} text-center`}
                        >
                            SITE DESIGNED AND DEVELOPED BY RARE
                        </a>

                        <Link
                            to="/support/"
                            className="hidden laptop:block text-dkgray"
                            style={{ marginLeft: '16px' }}
                        >
                            Privacy Policy
                        </Link>
                        
                        <div className={`${styles.madeWithContainer} flex w-full justify-around laptop:block`}>
                            <div className={`${styles.footnoteThree} flex items-center`}>
                                <span>Made with</span>
                                    <HeartSVG />
                                <span>in Chicago</span>
                            </div>
                        </div>
                    </div>

                    <div className="hidden laptop:flex w-1/4 items-end justify-end">
                        <div className="flex justify-around w-5/6" style={{ maxWidth: '220px', marginRight: "16px" }}>
                            <SocialIcon
                                SVG={FacebookSVG}
                                LinkType={ExternalLink}
                                href="https://www.facebook.com/rarechicagotech"
                            />
                            <SocialIcon
                                SVG={InstagramSVG}
                                LinkType={ExternalLink}
                                href="https://www.instagram.com/rarechicagotech"
                            />
                            <SocialIcon
                                SVG={TwitterSVG}
                                LinkType={ExternalLink}
                                href="https://www.twitter.com/rarechicagotech"
                            />
                            <SocialIcon
                                SVG={LinkedinSVG}
                                LinkType={ExternalLink}
                                href="https://www.linkedin.com/company/rarechicago"
                            />
                            <SocialIcon
                                SVG={GitHubSVG}
                                LinkType={ExternalLink}
                                href="https://github.com/rarechicago"
                            />
                        </div>
                    </div>


                </div>

            </div>

        </footer>
        </>
    )
}

export default Footer;