import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import ExternalLink from '../utils/external-link'

import styles from '../css/mobile-menu.module.css'

import ThreadsHome from '../images/rare-threads-mobile-home-icon.png'
import RareHome from '../icons/rare-chicago-home.svg'
import Products from '../icons/products.svg'
import About from '../icons/about.svg'
import Support from '../icons/support.svg'
import Facebook from '../icons/facebook.svg'
import Instagram from '../icons/instagram.svg'

const MobileMenu = ({ mobileMenu }) => {
    const backgroundImage = useStaticQuery(graphql`
    query {
        file(name: {eq: "mobile-menu-background"}) {
          childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

    const fluid = backgroundImage.file.childImageSharp.fluid

    const NavItem = ({ icon, alt, slug, label }) => (
        <Link to={slug} className="flex items-center">
            <img
                src={icon}
                alt={alt}
                style={{
                    width: '32px',
                    height: '32px',
                    marginBottom: '16px'
                }}
            />    
            <span
                className="inline-block text-dkblue"
                style={{
                    marginLeft: '16px',
                    marginBottom: '16px'
                }}
            >
                {label}
            </span>
        </Link>
    )

    const LinkItem = ({ icon, alt, LinkType, href, label }) => (
        <LinkType href={href} className="flex items-center">
            <img
                src={icon}
                alt={alt}
                style={{
                    width: '32px',
                    height: '32px',
                    marginBottom: '16px'
                }}
            />  
            <span
                className="inline-block text-dkblue"
                style={{
                    marginLeft: '16px',
                    marginBottom: '16px'
                }}
            >
                {label}
            </span>
        </LinkType>
    )

    return(
        <div
            className={`${styles.menuContainer} ${mobileMenu === true ? styles.menuActive : ``} bg-white laptop:hidden`}
        >
            <BackgroundImage style={{ padding: '16px 0 64px 0', minHeight: '100%' }} fluid={fluid}>

            <Link
                to="/"
            >
                <img
                    src={ThreadsHome}
                    style={{ width: '64px', height: '64px', marginLeft: '16px' }}
                    alt="Rare Threads square icon with large R in center - Link to threads home page."
                />
            </Link>

            <nav style={{ marginTop: '24px', paddingLeft: '16px' }}>
                <NavItem
                    icon={Products}
                    alt="Products icon"
                    slug="/products/"
                    label="Products"
                />
                <LinkItem
                    icon={About}
                    alt="Question mark icon"
                    LinkType={ExternalLink}
                    href="https://rarechicago.com/about/"
                    label="About Rare"
                />
                <NavItem
                    icon={Support}
                    alt="Support icon"
                    slug="/support/"
                    label="Support"
                />
            </nav>

            <div className="w-full opacity-20" style={{ padding: '0 8px' }}>
                <hr className="bg-dkblue h-2px" style={{ margin: '16px 0 32px 0' }} />
            </div>

            <nav className="text-xs opacity-80" style={{ marginTop: '8px', paddingLeft: '16px' }}>
                <LinkItem
                    icon={RareHome}
                    alt="Globe icon"
                    LinkType={ExternalLink}
                    href="https://rarechicago.com/"
                    label="rarechicago.com"
                />
                <LinkItem
                    icon={Facebook}
                    alt="Facebook icon"
                    LinkType={ExternalLink}
                    href="https://www.facebook.com/rarechicagotech"
                    label="@rarechicagotech"
                />
                <LinkItem
                    icon={Instagram}
                    alt="Instagram icon"
                    LinkType={ExternalLink}
                    href="https://www.instagram.com/rarechicagotech"
                    label="@rarechicagotech"
                />
            </nav>

            </BackgroundImage>

        </div>
    )
}

export default MobileMenu;