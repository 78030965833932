import React, { useContext } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'

import StoreContext from '../context/StoreContext'
import LineItem from './line-item'

import styles from '../css/shopping-cart.module.css'

import CloseSVG from '../icons/close.svg'
import AmericanExpress from '../icons/american-express.svg'
import Discover from '../icons/discover.svg'
import Mastercard from '../icons/mastercard.svg'
import Paypal from '../icons/paypal.svg'
import Visa from '../icons/visa.svg'

const ShoppingCart = ({ shoppingCart, setShoppingCart }) => {
    const backgroundImage = useStaticQuery(graphql`
    query {
        file(name: {eq: "shopping-cart-background"}) {
          childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    
    const fluid = backgroundImage.file.childImageSharp.fluid

    const { store: { checkout }, setScrollLock } = useContext(StoreContext)

    const handleCheckout = () => {
        window.open(checkout.webUrl)
    }

    const handleCloseCart = () => {
        setShoppingCart(false);
        setScrollLock(false);
    }

    const lineItems = checkout.lineItems.map(item => (
        <LineItem key={item.id} item={item} />
    ))

    return(
        <div className={`${styles.shoppingCart} ${ shoppingCart ? styles.cartActive : `` }`}>
            <BackgroundImage 
                fluid={fluid}
                style={{ minHeight: '100%', paddingTop: '18px' }}
            >
                <div className="mx-auto" style={{ maxWidth: '489px' }}>

                    <button
                        className="mr-2 float-right"
                        onClick={handleCloseCart}
                    >
                        <img
                            alt="Close menu icon"
                            src={CloseSVG}
                        />
                    </button>

                    <h2 className="text-black ml-1">Cart</h2>

                    <div className="bg-dkblue text-sm flex items-center" style={{ marginBottom: '2px' }}>
                        <div className={`${styles.col1}`}>
                            <p className="pl-1 my-1">Quantity</p>
                        </div>
                        <div className={`${styles.col2}`}>
                            <p className="pl-1 my-1">Product</p>
                        </div>
                        <div className={`${styles.col3} flex justify-around`}>
                            <p className="my-1">Price</p>
                        </div>
                    </div>

                    {lineItems}

                    <div className="bg-dkblue w-full text-sm flex items-center">
                        <p className="my-1 pl-4">{checkout.lineItems.length} Items</p>
                    </div>

                    <div className="w-full flex justify-end h-12 mb-6">
                        <div className={`${styles.subtotalCol1} bg-dkblue flex items-center rounded-bl-3xl pr-1 tablet:justify-end`}>
                            <span className="text-xs tablet:text-sm pl-8 m-0">Subtotal:</span>
                        </div>
                        <div className={`${styles.subtotalCol2} flex items-center justify-around bg-dkblue tablet:text-lg`}>
                            <span className='m-0'>${checkout.subtotalPrice}</span>
                        </div>
                        <div className={`${styles.subtotalCol3} bg-dkblue`} />
                    </div>

                    <button
                        className={`${checkout.lineItems.length === 0 ? `opacity-50` : `opacity-100`} 
                            text-dkblue font-bold px-16 py-2 mx-auto block mb-6
                        `}
                        style={{ 
                            background: 'linear-gradient(148deg, rgba(179,206,247,1) 0%, rgba(153,189,197,1) 100%)'
                        }}
                        onClick={handleCheckout}
                        disabled={checkout.lineItems.length === 0}
                    >
                        Checkout
                    </button>

                    <div className="flex justify-around mx-auto" style={{ width: '230px' }}>
                        <img
                            className={styles.paymentIcon}
                            alt="Visa logo"
                            src={Visa}
                        />
                        <img
                            className={styles.paymentIcon}
                            alt="American Express logo"
                            src={AmericanExpress}
                        />
                        <img
                            className={styles.paymentIcon}
                            alt="Mastercard logo"
                            src={Mastercard}
                        />
                        <img
                            className={styles.paymentIcon}
                            alt="Discover logo"
                            src={Discover}
                        />
                        <img
                            className={styles.paymentIcon}
                            alt="Paypal logo"
                            src={Paypal}
                        />
                    </div>
                </div>
            </BackgroundImage>
        </div>
    )
}

export default ShoppingCart;