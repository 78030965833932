import React, { useContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import reduce from 'lodash/reduce'
import StoreContext from '../context/StoreContext'

import styles from '../css/header.module.css'

const Header = ({ mobileMenu, setMobileMenu, shoppingCart, setShoppingCart }) => {

    const { currentPage, setScrollLock, mobileProductsMenu, shareWidget } = useContext(StoreContext)

    const useQuantity = () => {
        const { store: { checkout } } = useContext(StoreContext)
        const items = checkout ? checkout.lineItems : []
        const total = reduce(items, (acc, item) => acc + item.quantity, 0)
        return [total !== 0, total]
    }

    const [hasItems, quantity] = useQuantity()

    const ShoppingCartSVG = ({ className }) => (
        <svg viewBox="0 0 24 24" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.2964 11.97C17.9563 12.59 17.2964 13 16.5464 13H9.09631L7.99634 15H19.9963V17H7.99634C6.47632 17 5.51636 15.37 6.24634 14.03L7.59631 11.59L3.99634 4H1.99634V2H5.26636L6.2063 4H21.0063C21.7664 4 22.2463 4.82001 21.8763 5.48001L18.2964 11.97ZM19.3064 6H7.15637L9.52637 11H16.5464L19.3064 6ZM7.99634 18C6.89636 18 6.00635 18.9 6.00635 20C6.00635 21.1 6.89636 22 7.99634 22C9.09631 22 9.99634 21.1 9.99634 20C9.99634 18.9 9.09631 18 7.99634 18ZM16.0063 20C16.0063 18.9 16.8964 18 17.9963 18C19.0963 18 19.9963 18.9 19.9963 20C19.9963 21.1 19.0963 22 17.9963 22C16.8964 22 16.0063 21.1 16.0063 20Z"/>
        </svg>
    )

    const handleMobileMenu = () => {
        if (mobileMenu === true) {
            setMobileMenu(false);
            setScrollLock(false);
        } else {
            setMobileMenu(true);
            setScrollLock(true);
        }
    }

    const handleCart = () => {
        if (shoppingCart === true) {
            setShoppingCart(false);
            setScrollLock(false);

        } else {
            setShoppingCart(true);
            setScrollLock(true);
        }
    }

    const menuDisabled = shoppingCart === true || mobileProductsMenu === true || shareWidget === true ? true : false
  
  return(
      <header className={`${styles.header} bg-dkblue bg-opacity-90`}>
        <div className={`${styles.navigation} max-viewport flex items-center justify-between mx-auto`}>

            <Link
                to="/"
            >
                <StaticImage
                    alt="Rare Chicago clouds logo - Link to home page"
                    src="../images/rare-chicago-clouds-blue.png"
                    loading="eager"
                    placeholder="tracedSVG"
                    style={{
                        width: '80px',
                        display: 'block'
                    }}
                />
            </Link>

            <div className="flex items-center">

                <nav className="hidden laptop:flex">
                    <Link 
                        to="/products/"
                        style={{ marginRight: '32px' }}
                        className={`${styles.desktopNavLink} ${currentPage === `Products` && styles.desktopNavLinkActive}`}
                    >
                        Products
                    </Link>
                    <a
                        href="https://rarechicago.com/about/"
                        style={{ marginRight: '32px' }}
                        className={`${styles.desktopNavLink}`}
                    >
                        About Rare
                    </a>
                    <Link
                        to="/support/"
                        style={{ marginRight: '32px' }}
                        className={`${styles.desktopNavLink} ${currentPage === `Support` && styles.desktopNavLinkActive}`}
                    >
                        Support
                    </Link>
                </nav>

                <button
                    className={styles.cartButton}
                    aria-label="Open/close shopping cart"
                    onClick={handleCart}
                >
                    { hasItems &&
                        <div className={`${styles.cartCounter} text-black flex items-center justify-around`}>{ quantity }</div>
                    }
                    <div className={`${styles.cartContainer} ${shoppingCart === true && styles.cartContainerActive}`}>
                        <ShoppingCartSVG className="fill-current"/>
                    </div>
                </button>


                <button
                    className="block grid grid-cols-1 gap-y-0 laptop:hidden"
                    aria-label="Open/close navigation menu"
                    onClick={handleMobileMenu}
                    disabled={menuDisabled}
                >
                    <div className={`${styles.bar1} ${menuDisabled === true && styles.barDisabled}`} id={mobileMenu ? styles.changebar1 : "bar1"}></div>
                    <div className={`${styles.bar2} ${menuDisabled === true && styles.barDisabled}`} id={mobileMenu ? styles.changebar2 : "bar2"}></div>
                    <div className={`${styles.bar3} ${menuDisabled === true && styles.barDisabled}`} id={mobileMenu ? styles.changebar3 : "bar3"}></div>
                </button>

              </div>   

        </div>
      </header>
    )
}

export default Header;
